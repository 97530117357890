<template>
  <div class="profile-shipment-details max-width">
    <div
      class="
        profile-shipments-details__tabs
        d-flex
        align-center
        justify-space-start
      "
      v-if="item.id"
    >
      <div
        class="
          profile-shipments-details__tabs__tab
          w-30
          text-center
          cursor-pointer
          mr-3
          pt-2
          margen-res
        "
        :class="
          tab.active ? 'profile-shipments-details__tabs__tab--active' : ''
        "
        v-for="(tab, i) in tabs"
        :key="i"
        @click="tabClicked(tab)"
      >
        <span
          class="font-25"
          :class="tab.active ? 'halfwhite--text' : 'grey--text'"
          >{{ tab.title }}</span
        >
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div :key="keyUpdated" v-if="item.id">
        <component
          :is="selectedSection"
          class="mt-7"
          :item="item"
          :loading="loading"
          @itemUpdated="getData"
          @cancelHandler="getData"
        ></component>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: (vm) => ({
    reActive: false,
    tabs: [
      {
        title: vm.$t("status"),
        name: "status",
        active: true,
        component: () => import("./components/StatusSection"),
      },
      {
        title: vm.$t("details"),
        name: "details",
        active: false,
        component: () => import("./components/DetailSection"),
      },
      {
        title: vm.$t("currentBids"),
        name: "currentBids",
        active: false,
        component: () => import("./components/BidsSection"),
      },
    ],
    item: {},
    selectedSection: () => import("./components/StatusSection"),
    keyUpdated: false,
    loading: false,
    refleshActive: false,
  }),
  watch: {
    $route: {
      async handler() {
        await this.getData();
      },
    },
    reActive(val) {
      this.reActive = !val;
    },
    selectedIdChate(){
      this.getData();
    }
  },
  methods: {
    tabClicked(tab) {
      this.selectedSection = tab.component;
      this.keyUpdated = !this.keyUpdated;
      this.tabs.forEach((t) => {
        if (t.title == tab.title) {
          t.active = true;
        } else {
          t.active = false;
        }
      });
    },
    async getData() {
      this.loading = true;
      let { data } = await this.$axios.get(
        `/${this.$route.name}/${this.$route.params.id}`
      );
      let routeName;
      if (this.$route.name == "myProduct") {
        routeName = "product";
      } else {
        routeName = "shipment";
      }
      if (data.success) {
        this.item = data.data[routeName];
        if (this.item.status != "pending") {
          this.tabs = [...this.tabs.filter((tab) => tab.name != "currentBids")];
        }
        if (this.$route.name == "myShipment") {
          this.item.bids = [
            ...data.data[routeName].bids.map((item) => {
              return {
                route: "",
                id: item.id,
                title: item.bringer_name,
                rate: item.rate,
                image:
                  item.bringer_image == "#"
                    ? this.$defaultImage
                    : item.bringer_image,
                images: [],
                fromCountry: item.origin_country,
                fromCity: item.origin_city,
                toCountry: item.destination_country,
                toCity: item.destination_city,
                date: item.expected_date,
                weight: item.weight,
              };
            }),
          ];
        } else {
          this.item.bids = [
            ...data.data[routeName].bids.map((item) => {
              return {
                route: "",
                id: item.id,
                title: item.bringer_name,
                rate: item.rate,
                image:
                  item.bringer_image == "#"
                    ? this.$defaultImage
                    : item.bringer_image,
                images: [],
                fromCountry: item.origin_country,
                fromCity: item.origin_city,
                toCountry: item.destination_country,
                toCity: item.destination_city,
                date: item.expected_date,
                weight: item.weight,
              };
            }),
          ];
        }
      }
      this.keyUpdated = !this.keyUpdated;
      this.loading = false;
    },
  },
  computed:{
    ...mapGetters(["selectedIdChate"]),
  },

  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss" >
.profile-shipments-details {
  &__tabs {
    border-bottom: 2px solid #66656533;

    &__tab {
      border-top: 1px solid #66656533;
      border-left: 1px solid #66656533;
      border-right: 1px solid #66656533;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      padding-left: 30px !important;
      padding-right: 30px !important;

      &:after {
        content: "";
        border-bottom: 1px solid #66656533;
        transition: 0.3s;
        position: absolute;
        bottom: -2px;
        left: 0px;
        width: 100%;
        border-radius: 4px;
      }
      &--active {
        background: #001489;
        &:after {
          // border-bottom: 3px solid #c23423;
          display: none;
        }
      }
    }
  }
  @media (max-width: 500px) {
    &__tabs {
      &__tab {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
  }
}
</style>
